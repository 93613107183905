<template>

  <div>

    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="2"
          class="w-100"
        >
          <v-select
            v-model="filterDataTuition"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterTuitionFeeOptions"
            :clearable="false"
            :reduce="item => item.value"
            :placeholder="'Select Fee Type'"
          />
        </b-col>

        <b-col
          cols="12"
          md="2"
          class="w-100"
        >
          <v-select
            v-model="filterDataStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterVisibleStatusOptions"
            :clearable="false"
            :reduce="item => item.value"
            :placeholder="'Select Visible'"
          />
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />

              <b-button
                v-if="$can('create', permissionSubjects.GroupType)"
                v-b-modal.add-group-type
                class="ml-1"
                variant="primary"
              >
                <span class="mr-25 align-middle">Add New</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchGroupTypesList"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        sticky-header="75vh"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-sticky-head"
      >
        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: is_subgroup -->
        <template #cell(is_subgroup)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.value)}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: is_visible -->
        <template #cell(is_visible)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.item.settings ? data.item.settings.is_visible : false)}`"
          >
            {{ data.item.settings ? data.item.settings.is_visible : false }}
          </b-badge>
        </template>

        <!-- Column: tuition -->
        <template #cell(tuition)="data">
          <b-badge variant="light-secondary">
            {{ data.item.settings && data.item.settings.has_tuition_fee ? 'Tuition' : 'Fee' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <template
              v-for="option in actionOptions"
            >
              <b-dropdown-item
                v-if="option.link"
                :key="option.value"
                :to="{ name: option.link, params: { id: data.item.id } }"
              >
                <span>{{ option.title }}</span>
              </b-dropdown-item>
            </template>

            <b-dropdown-item
              v-if="$can('update', permissionSubjects.GroupType)"
              v-b-modal.edit-group-type
              @click="editableGroupTypeId = data.item.id"
            >
              <span>Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.GroupType)"
              @click="deleteItem(data.item)"
            >
              <span>Delete</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="openGenerateGroupListTemplateModal(data.item)"
            >
              <span>Bulk Group List Template</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

    <group-type-create-modal
      @reload="refetchData"
    />

    <group-type-edit-modal
      v-if="editableGroupTypeId"
      :group-type-id="editableGroupTypeId"
      @reload="refetchData"
    />

    <confirmation-modal
      :toggle-modal="confirmationModalToggle"
      :type="'-delete-group-type'"
      :title="'Are you sure'"
      :message="'Are you sure you want to delete this group type?'"
      @close-modal="confirmationModalToggle = !confirmationModalToggle"
      @confirm="deleteGroupTypeIfExist"
    />

    <warning-modal
      :toggle-modal="warningOnDeleteModalToggle"
      :type="'-delete-group-type'"
      :title="'This Group type can\'t be deleted because there are some groups created with this type'"
      :message="'Please delete Groups with this type first'"
      @close-modal="warningOnDeleteModalToggle = !warningOnDeleteModalToggle"
    />

    <warning-modal
      :toggle-modal="warningOnEditModalToggle"
      :type="'-edit-group-type'"
      :title="'We cannot make those changes because groups in this type have Users assigned'"
      :message="'Only Group Type name is editable'"
      @close-modal="warningOnEditModalToggle = !warningOnEditModalToggle"
    />

    <generate-group-list-template-modal
      v-if="selectedGroupType"
      :generate-mode="'groupType'"
      :group-type-id="selectedGroupType.id"
      @close="resetSelectedGroupType"
    />
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BBadge, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import { downloadExportFile } from '@core/mixins/downloadExportFile'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/group-types/groupTypeStoreModule'
import useGroupTypesList from '@/views/admin/group-types/group-types-list/useGroupTypesList'
import GroupTypeCreateModal from '@/views/admin/group-types/goup-types-add/GroupTypeCreateModal.vue'
import GroupTypeEditModal from '@/views/admin/group-types/group-types-edit/GroupTypeEditModal.vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import WarningModal from '@/views/components/warning-modal/WarningModal.vue'
import GenerateGroupListTemplateModal from '@/views/components/generate-group-list-template-modal/GenerateGroupListTemplateModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BButton,

    vSelect,
    GroupTypeCreateModal,
    GroupTypeEditModal,
    TableSpinner,
    ConfirmationModal,
    WarningModal,
    GenerateGroupListTemplateModal,
  },
  mixins: [downloadExportFile],
  data() {
    return {
      isLoading: false,
      confirmationModalToggle: false,
      warningOnEditModalToggle: false,
      warningOnDeleteModalToggle: false,
      editableGroupTypeId: null,
      deletableGroupTypeId: null,
      selectedGroupType: null,
      permissionSubjects,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-group-type'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchGroupTypesList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      filterDataStatus,
      filterDataTuition,
      searchQuery,
      filterStatusOptions,
      sortBy,
      totalItems,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      actionOptions,
      filterVisibleStatusOptions,
      filterTuitionFeeOptions,

      resolveStatusVariant,

      refetchData,
      clearFilters,

      postGroupType,
      deleteGroupType,
    } = useGroupTypesList(root)

    return {
      fetchGroupTypesList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      filterDataStatus,
      filterDataTuition,
      searchQuery,
      filterStatusOptions,
      sortBy,
      totalItems,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      actionOptions,
      filterVisibleStatusOptions,
      filterTuitionFeeOptions,

      resolveStatusVariant,

      refetchData,
      clearFilters,

      postGroupType,
      deleteGroupType,
    }
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    deleteItem(item) {
      if (item.groups_count > 0) {
        this.$bvModal.show('warning-delete-group-type')
      } else {
        this.openDeleteConfirmation(item)
      }
    },
    openDeleteConfirmation(item) {
      this.deletableGroupTypeId = item.id
      this.$bvModal.show('confirmation-delete-group-type')
    },
    async deleteGroupTypeIfExist() {
      if (this.deletableGroupTypeId) {
        await this.deleteGroupType(this.deletableGroupTypeId).then(() => {
          this.$bvModal.hide('confirmation-delete-group-type')
          this.refetchData()
        })
      }
    },
    updatePerPage(val) {
      localStorage.setItem('groupTypesPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('groupTypesPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.sortBy = query.sortBy || 'created_at'
      this.filterDataStatus = query.filterDataStatus || ''
      this.filterDataTuition = query.filterDataTuition || ''
    },
    openGenerateGroupListTemplateModal(val) {
      this.selectedGroupType = val
      this.$nextTick(() => {
        this.$bvModal.show('generate-group-list-template')
      })
    },
    resetSelectedGroupType() {
      this.selectedGroupType = null
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .bulk-checkbox {
    margin-left: 0.5rem;
  }
</style>
